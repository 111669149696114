var outletHomepage = (function () {
	var tinySlider, homepageSlider, thirdPartyModal,
	homeScroller = document.querySelector('.homeScroller'),
	
	init = function () {
		if (document.getElementById("expiringScroller"))  initializeScroller("expiringScroller");
		if (document.getElementById("newestScroller")) initializeScroller("newestScroller");
		
		initHomePageScroller();
		initFocus();
		checkUserIsThirdParty();
	},
	initializeScroller = function (sid) {
		var container = document.getElementById(sid),
			slider = container.querySelector('.Scroller');
		if (slider) {
			tinySlider = new tns({
				container: slider,
				mouseDrag: true,
				lazyload: true,
				prevButton: container.querySelector('.slider-prev'),
				nextButton: container.querySelector('.slider-next'),
				items: 1,
				gutter: 20,
				slideBy: "page",
				nav: false,
				navPosition: "bottom",
				loop: false,
				responsive: {
					1024: {
						items: 5,
						nav: true
					},
					800: {
						items: 4,
						nav: true
					},
					480: {
						items: 3
					}
				}
			});

			setTimeout(function () {
				if (container.classList.contains("invisible")) {
					// allow the slider time to build and remove invisible class after so user doesn't see the process
					container.classList.remove("invisible");
				}
			}, 200);
		}
	},
		initHomePageScroller = function () {
			var pauseIcon = "<svg class='textDkGray' width='30' height='30' focusable='false'><title>Pause Scrolling</title> <use xlink:href='#svgIcon-pause'></use> </svg>";
			var playIcon = "<svg class='textDkGray rotate90' width='30' height='30' focusable='false'><title>Resume Scrolling</title> <use xlink:href='#svgIcon-arrowSolid'></use> </svg>";
			homepageSlider = new tns({
				container: document.querySelector('.homeScroller'),
				lazyload: true,
				controls: false,
				nav: true,
				navPosition: "bottom",
				loop: true,
				autoplay: true,
				speed: 1000,
				autoplayTimeout: 5500,
				autoplayHoverPause: true,
				autoplayPosition: "top",
				autoplayButton: "#pausePlayBtn",
				autoplayText: [
					playIcon,
					pauseIcon
				]
			});

			homepageSlider.events.on('transitionStart', function () {
				// set the active slide button to tabindex 0 and the rest to -1 so they cannot be tabbed to
				var slides = [].slice.call(homeScroller.querySelectorAll(".tns-item"));
				slides.forEach(function (el, index, array) {
					el.querySelector("a").setAttribute("tabindex", "-1");
				});
				homeScroller.querySelector(".tns-slide-active").querySelector("a").setAttribute("tabindex", "0");
			});

			var sliderPages = [].slice.call(document.querySelector(".homepageBanner").querySelector(".tns-nav").querySelectorAll("button"));
			sliderPages.forEach(function (el) {
				el.addEventListener("click", function (e) {
					e.preventDefault();
					homepageSlider.events.on('transitionEnd', setFocus);
				});
			});
			
			document.querySelector(".homepageBanner").classList.remove("invisible");
		},
		setFocus = function () {
			document.querySelector(".tns-slide-active").querySelector("a").focus();
			homepageSlider.events.off('transitionEnd', setFocus);
		},
		ajaxActions = function () {
			var getCurrentDOTD = function () {
				fetch('/home/dealofthedaybanner', { headers: { 'x-requested-with': 'fetch' } }).then(function (response) {
					return (response.ok) ? response.text() : '';
				}).then(function (html) {
					if (html.length < 1) { return; }
					document.getElementById('dealOfTheDayBanner').innerHTML = (html);
				});
			},

				getNewestScroller = function () {
					fetch('/home/newestitemsscroller', { headers: { 'x-requested-with': 'fetch' } }).then(function (response) {
						return (response.ok) ? response.text() : '';
					}).then(function (html) {
						if (html.length < 1) { return; }
						document.getElementById('newestScroller').innerHTML = (html);
						initializeScroller("newestScroller");
					});

				};

			return {
				GetCurrentDOTD: getCurrentDOTD,
				GetNewestScroller: getNewestScroller
			};
		},
		initFocus = function () {
			var options;
			var observer = new IntersectionObserver(callBack, options);
			/* once element is in view - do this */
			function callBack(entries, observer) {
				entries.forEach(function (entry) {
					if (entry.intersectionRatio > 0) {
						if (entry.target === document.getElementById('dealOfTheDayBanner')) {
							ajaxActions().GetCurrentDOTD();
							observer.unobserve(document.getElementById('dealOfTheDayBanner'));
						}
						if (entry.target === document.getElementById('newestScroller')) {
							ajaxActions().GetNewestScroller();
							observer.unobserve(document.getElementById('newestScroller'));
						}
					}
				});
			}
			/* related product scroller */
			if (document.getElementById('dealOfTheDayBanner')) {
				options = { root: document.getElementById('dealOfTheDayBanner'), threshold: 1.0 };
				observer.observe(document.getElementById('dealOfTheDayBanner'));
			}

			/* newest items - added in the last 30 days */
			if (document.getElementById('newestScroller')) {
				options = { root: document.getElementById('newestScroller'), threshold: 1.0 };
				observer.observe(document.getElementById('newestScroller'));
			}
		},
		checkUserIsThirdParty = function () {
			fetch('/info/isUserThirdParty', {
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "Cache-Control": "no-cache" }
			}).then(function (response) {
				return (response.ok) ? response.json() : []; // response.json() returns a promise
			}).then(function (responsebody) {
				if (responsebody.isThirdParty){
					thirdPartyDialog.Init();
					thirdPartyModal.Open();
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		thirdPartyDialog = (function(){
			let init = () => {
				thirdPartyModal = new Dialog4i();
				thirdPartyModal.Init({
					triggerElements: [],
					dialogClass: "mediumDialog", 
					title: "Warning", 
					content: getContent()
				});
			},
			getContent = () => {
				return '<p class="marginBtm10 textCenter">You are entering a site that is not supported by Punchout, please press the "Return to Punchout" button to restore your session.</p>' +
            				'<div class="displayFlex flexJustifyCenter padding10">' +
                				'<a class="button-lg text16 bkgdBlue whiteLink" href="https://www.4imprint.com">Return to Punchout</a>' +
            				'</div>';
            		};
			return{
				Init: init
			};
		})();

	return {
		Init: init
	};
})();

(function () {
	setTimeout(function () { // temporary since nothing is initializing on the page w/o it
		outletHomepage.Init();
	}, 300);
})();